<template>
  <button v-if="authStore.isLoggedIn && memorialProfile.owner === authStore.currentUserId" class="floating-edit-button" @click="navigateToEditPage">
    <v-icon class="edit-icon">mdi-pencil-circle-outline</v-icon>
  </button>

  <div class="scroll-container">
    <CoverPage :profile-data="memorialProfile" @scroll-to-second-page="handleScrollToPage"/>
    <EducationPage
      v-for="educationItem in (memorialProfile.sections || []).find(section => section.key === 'education')?.items || []"
      :key="educationItem.id"
      :education-data="educationItem"
    />
    <ImportantEventsPage
      v-for="eventItem in (memorialProfile.sections || []).find(section => section.key === 'important_events')?.items || []"
      :key="eventItem.id"
      :event-data="eventItem"
    />
    <WorkPage
      v-for="workItem in (memorialProfile.sections || []).find(section => section.key === 'work')?.items || []"
      :key="workItem.id"
      :work-data="workItem"
    />
    <AchievementsPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'achievements')?.items?.length > 0"
      :achievement-items="(memorialProfile.sections || []).find(section => section.key === 'achievements')?.items || []"
    />
    <FamilyPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'family')?.items?.length > 0"
      :family-data="(memorialProfile.sections || []).find(section => section.key === 'family')?.items || []"
    />
    <HobbiesPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'interests')?.items.length > 0"
      :hobby-items="(memorialProfile.sections || []).find(section => section.key === 'interests')?.items || []"
    />
    <AdditionalDescriptionPage
      v-for="additionalDescriptionItem in (memorialProfile.sections || []).find(section => section.key === 'additional_description')?.items || []"
      :key="additionalDescriptionItem.id"
      :additional-description-data="additionalDescriptionItem"
    />
    <GalleryPage
      v-if="(memorialProfile.sections || []).find(section => section.key === 'gallery')?.items?.length > 0"
      :gallery-data="(memorialProfile.sections || []).find(section => section.key === 'gallery')"
    />
    <FinalPage :profile-data="memorialProfile" @scroll-to-top="handleScrollToTop"/>
  </div>
</template>

<script>
import CoverPage from '@/views/MemorialProfilePages/CoverPage.vue';
import EducationPage from '@/views/MemorialProfilePages/EducationPage.vue';
import AchievementsPage from '@/views/MemorialProfilePages/AchievementsPage.vue';
import WorkPage from '@/views/MemorialProfilePages/WorkPage.vue';
import FinalPage from '@/views/MemorialProfilePages/FinalPage.vue';
import AdditionalDescriptionPage from '@/views/MemorialProfilePages/AdditionalDescriptionPage.vue';
import FamilyPage from '@/views/MemorialProfilePages/FamilyPage.vue';
import HobbiesPage from '@/views/MemorialProfilePages/HobbiesPage.vue';
import ImportantEventsPage from '@/views/MemorialProfilePages/ImportantEventsPage.vue';
import GalleryPage from '@/views/MemorialProfilePages/GalleryPage.vue';
import {useAuthStore} from "@/stores/auth";
import {useMemorialProfileStore} from "@/stores/memorial-profile";

export default {
  components: {
    CoverPage,
    EducationPage,
    FamilyPage,
    FinalPage,
    AdditionalDescriptionPage,
    WorkPage,
    AchievementsPage,
    HobbiesPage,
    ImportantEventsPage,
    GalleryPage,
  },
  props: {
    slotNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    const authStore = useAuthStore();

    return {
      authStore,
      memorialProfile: {
        title: '',
        description: '',
      },
      apiUrl: process.env.VUE_APP_API_URL,
      error: null,
    };
  },
  mounted() {
    this.fetchMemorialProfile();
    this.setupScrollSnap();
  },
  methods: {
    async fetchMemorialProfile() {
      try {
        const store = useMemorialProfileStore();
        this.memorialProfile = store._memorialProfile;

        if (!this.memorialProfile.sections) {
          this.memorialProfile.sections = [];
        }

        if (!this.memorialProfile.sections.find(section => section.key === 'work')) {
          this.memorialProfile.sections.push({ category: 'work', items: [] });
        }
      } catch (error) {
        console.error(error);
      }
    },
    setupScrollSnap() {
      const scrollContainer = document.querySelector('.scroll-container');
      scrollContainer.style.scrollSnapType = 'y mandatory';
      scrollContainer.style.overflowY = 'scroll';
      scrollContainer.style.height = 'calc(100vh - 64px)';

      Array.from(scrollContainer.children).forEach(child => {
        child.style.scrollSnapAlign = 'start';
      });
    },
    handleScrollToTop() {
      const scrollContainer = document.querySelector('.scroll-container');
      scrollContainer.scrollTo({top: 0, behavior: 'smooth'});
    },
    handleScrollToPage(index) {
      const pages = document.querySelectorAll('.profile-page-container-wrapper');

      if (pages.length > index) {
        pages[index].scrollIntoView({behavior: 'smooth'});
      }
    },
    navigateToEditPage() {
      if (this.authStore.isLoggedIn && this.memorialProfile.owner === this.authStore.currentUserId) {
        this.$router.push(`/profil-pamieci/${this.slotNumber}/edytuj`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  height: calc(100vh - 64px); /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  overflow-y: scroll; /* Enable vertical scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-snap-type: y mandatory; /* Enable full section scroll snapping */
  background-image:
      url('../assets/images/leaves-top-left.png'),
      url('../assets/images/leaves-bottom-left.png'),
      url('../assets/images/leaves-top-right.png'),
      url('../assets/images/leaves-bottom-right.png');
  background-position: top left, bottom left, top right, bottom right;
  background-repeat: no-repeat;
  background-size: 30%;

  @media (min-width: 768px) {
    background-size: auto;
  }
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container > * {
  scroll-snap-align: start; /* Align children to the start */
}

.floating-edit-button {
  position: fixed;
  top: 76px;
  right: 16px;
  background-color: rgba(#ffffff, 0.5);
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, opacity 0.3s ease;
  color: #075985;
  opacity: 0.3;

  &:hover {
    opacity: 1;
    background-color: #ffffff;

    &::before {
      content: 'Edytuj profil';
      position: absolute;
      left: -92px; /* Adjust as needed */
      top: 50%;
      transform: translateY(-50%);
      background-color: #ffffff;
      color: #075985;
      padding: 4px 12px 4px 8px;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      white-space: nowrap;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  .edit-icon {
    font-size: 3rem;
  }
}
</style>